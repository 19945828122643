import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WabagLogo from "../images/wabag_logo.png";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { AppContexts } from "./utils/AppContext";

const MainScreen = () => {

  const { stages } = useContext(AppContexts);
  console.log(stages)
  const color1 = stages > 0 ? 'success' : 'warning';
  const color2 = stages > 0 ? 'success' : 'warning';
  const color3 = stages > 1 ? 'success' : 'warning';
  const [btnDisabled1, setBtnDisabled1] = useState(false)
  const [btnDisabled2, setBtnDisabled2] = useState(false)
  const [btnDisabled3, setBtnDisabled3] = useState(false)

  const date1 = stages > 0 ? `${new Date().getDate().toString().padStart(2, '0')}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getFullYear()} ${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}` : "";
  const date2 = stages > 1 ? `${new Date().getDate().toString().padStart(2, '0')}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getFullYear()} ${new Date().getHours().toString().padStart(2, '0')}:${new Date().getMinutes().toString().padStart(2, '0')}` : "";

  const handleNext = () => {
    window.location.reload();
  };

  let navigate = useNavigate();

  const windowWidth = useRef(window.innerWidth);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    if (stages === 0) {
      setBtnDisabled2(false);
      setBtnDisabled3(true);
    } else if (stages === 1) {
      setBtnDisabled2(true);
      setBtnDisabled3(false);
    } else {
      setBtnDisabled2(true);
      setBtnDisabled3(true);
    }
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100wh",
          maxHeight: "100vh",
          height: "100vh",
        }}
      >
        <Grid container>

          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            gap={1}
            sx={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              sx={{
                background: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                height: "100%",
                borderRadius: "10px",
                marginLeft: "10px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: 0.2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25%",
                  marginBottom: "10%"
                }}
              >
                <img
                  src={WabagLogo}
                  width={windowWidth.current / 2}
                  height={windowWidth.current / 4}
                  alt="WABAG"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    screenSize.width < screenSize.height ? "column" : "row",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    flex: 0.5,
                    fontSize: windowWidth.current / 20,
                    fontFamily: "monospace",
                  }}
                >
                  {`Manhole Cleaning Cycle`}
                </Typography>
                <Typography
                  sx={{
                    color: "#000000",
                    flex: 0.5,
                    fontSize: windowWidth.current / 20,
                    marginBottom: "5%",
                    fontFamily: "monospace",
                  }}
                >
                  {`TRUCK : DL06AC8291`}
                </Typography>
                <Timeline>
                  {/* <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color={color1} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        <Button
                          variant="contained"
                          sx={{
                            height: screenSize.height / 20,
                            width: "100px",
                            fontSize: windowWidth.current / 35,
                          }}
                          onClick={() => navigate("/scan")}
                          disabled={btnDisabled1}
                        >
                          CLOSED
                        </Button>
                      </div>
                    </TimelineContent>
                  </TimelineItem> */}

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color={color2} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        <Button
                          variant="contained"
                          sx={{
                            height: screenSize.height / 20,
                            width: "100px",
                            fontSize: windowWidth.current / 25,
                            fontFamily: "monospace"
                          }}
                          onClick={() => navigate("/scan")}
                          disabled={btnDisabled2}
                        >
                          BEFORE
                        </Button>
                        <Typography
                          sx={{
                            color: "#000000",
                            flex: 0.05,
                            fontSize: windowWidth.current / 35,
                            marginLeft: "5%",
                            fontFamily: "monospace"
                          }}
                        >
                          {date1}
                        </Typography>
                      </div>
                    </TimelineContent>
                  </TimelineItem>

                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color={color3} />
                    </TimelineSeparator>
                    <TimelineContent>
                      <div>
                        <Button
                          variant="contained"
                          sx={{
                            height: screenSize.height / 20,
                            width: "100px",
                            fontSize: windowWidth.current / 25,
                            fontFamily: "monospace"
                          }}
                          onClick={() => navigate("/scan")}
                          disabled={btnDisabled3}
                        >
                          AFTER
                        </Button>
                        <Typography
                          sx={{
                            color: "#000000",
                            flex: 0.05,
                            fontSize: windowWidth.current / 35,
                            fontFamily: "monospace",
                            marginLeft: "5%"
                          }}
                        >
                          {date2}
                        </Typography>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    screenSize.width < screenSize.height ? "column" : "row",
                  width: "80%",
                  justifyContent: "space-evenly",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: screenSize.height / 18,
                    width: screenSize.width < screenSize.height ? "40%" : "30%",
                    fontSize: windowWidth.current / 25,
                    fontFamily: "monospace"
                  }}
                  onClick={handleNext}

                >
                  NEXT
                </Button>
              </div>
              <Typography
                sx={{
                  color: "#000000",
                  flex: 0.05,
                  fontSize: windowWidth.current / 35,
                  marginBottom: "5%"
                }}
              >
                {`Copyright @ ${new Date().getFullYear()} Alai Labs Pte Ltd. All rights reserved`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default MainScreen;
