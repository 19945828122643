"use client"
import React, { createContext, useState } from "react";

export const AppContexts = createContext(null);
const AppContext = ({ children }) => {
    const [stages, setStages] = useState(0)
    return (
        <AppContexts.Provider value={{ stages, setStages }}>
            {children}
        </AppContexts.Provider>
    )
}

export default AppContext;