import { Typography } from "@mui/material";
import React, { useEffect, useState, useRef, memo } from "react";

const ModelName = (props) => {
  console.log("props", props)
  const data = props.data;
  const label = data?.label;
  console.log("label", label)
  const confidence = parseFloat(data?.confidence.toFixed(2));
  const windowWidth = useRef(window.innerWidth);

  const [labelData, setLabelData] = useState("");

  useEffect(() => {
    if (parseFloat(data?.confidence.toFixed(2)) > 0.9) {
      setLabelData(data.label);
      props.setmodelname(data.label);
    } else {
      setLabelData("No Matches Found");
      props.setmodelname("No Matches Found");
    }
  }, [props.data]);
  return (
    <div>
      <Typography
        sx={{ fontWeight: "800", fontSize: windowWidth.current / 25, fontFamily: "monospace" }}
      >
        Status :{" "}
        {labelData === "No Matches Found" ? "No Matches Found" : label}
      </Typography>
      {/* <Typography
        sx={{ fontWeight: "800", fontSize: windowWidth.current / 25, fontFamily: "monospace", marginTop: "5%" }}
      >
        Confidence Level :{confidence}
      </Typography> */}
    </div>
  );
};
export default memo(ModelName);
