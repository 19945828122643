import React, { createContext } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import CameraScreen from "./Screens/CameraScreen";
import ScanComp from "./Screens/ScanComp";
import MainScreen from "./Screens/MainScreen";


const App = () => {
  return (
    
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path="/scan" element={<ScanComp />} />
        <Route path="/cam" element={<CameraScreen />} />
      </Routes>
    
  );
};

export default App;
