import React, { useEffect, useRef, useState } from "react";
import ml5 from "ml5";
import Loader from "react-loader-spinner";
import useInterval from "@use-it/interval";
import Chart from "../Chart";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../App.css";

let classifier;

function CameraScreen() {
  const videoRef = useRef();
  const [start, setStart] = useState(true);
  const [result, setResult] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const windowWidth = useRef(window.innerWidth);
  const windowHeight = useRef(window.innerHeight);
  const dimensions = {
    width: 400,
    height: 500,
  };

  const canvasRef = useRef();

  useEffect(() => {
    classifier = ml5.imageClassifier("./model/model.json", () => {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" }, audio: false })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          setLoaded(true);
        });
    });
    canvasRef.current.width = 400;
    canvasRef.current.height = 500;
  }, []);

  useInterval(() => {
    if (classifier && start) {
      classifier.classify(videoRef.current, (error, results) => {
        const { width, height } = dimensions;
        const ctx = canvasRef.current.getContext("2d");
        ctx.clearRect(0, 0, width, height);
        if (error) {
          console.error(error);
          return;
        }
        results.forEach((detection) => {
          ctx.beginPath();
          ctx.fillStyle = "red";
          const { label, x, y, width, height } = detection;
          console.log("detection...", detection);
          ctx.fillText(detection.label, x, y - 5);
          ctx.rect(x, y, width, height);
          ctx.stroke();
        });
        setResult(results);
        // console.log(results)
      });
    }
  }, 500);

  const toggle = () => {
    setStart(!start);
    setResult([]);
  };

  return (
    <Box
      sx={{
        width: "100wh",
        height: "100vh",
      }}
    >
      <Loader
        type="Watch"
        color="#00BFFF"
        height={200}
        width={200}
        visible={!loaded}
        style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
      />
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          xl={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <div style={{ width: 400, height: 500, overflow: "hidden" }}>
            <video
              playsInline={true}
              ref={videoRef}
              style={{ transform: "scale(1, 1)" }}
              width={400}
              height={500}
            />
            <canvas
              ref={canvasRef}
              style={{ position: "relative", zIndex: 99999 }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={12} xl={12}>
          <Box
            sx={{
              width: "100wh",
              height: windowHeight.current / 3.5,
            }}
          >
            <Chart data={result[0]} results={result} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CameraScreen;
